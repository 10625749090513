<template>
  <div style="height: calc(100% - 60px)">
    <!-- 主体内容 -->
    <div style="height: calc(100vh - 60px)">
      <div class="no_img" v-if="list.length == 0">
        <img src="../../public/img/gif/2.gif" />
      </div>

      <el-container v-else class="container">
        <!-- 经典语录 -->
        <div class="text" v-if="text_Vis">
          <span>{{ text_content }}</span
          ><i class="el-icon-refresh-right" @click="text_lu"></i
          ><i class="el-icon-circle-close" @click="text_Vis = false"></i>
        </div>

        <!-- 头部 -->
        <el-header class="head">
          <div class="hidden-xs-only" style="margin-right: 10px">
            <span>
              <el-tooltip
                class="item"
                effect="dark"
                content="只展示7天内修改过的数据,要获取所有数据请点击右侧 获取数据"
                placement="bottom"
                ><i class="el-icon-warning cur"></i>
              </el-tooltip>
              数据{{ this.list.length }}-{{ new_list_len }}-{{
                this.new_list.length
              }}
              在线人数:{{ users }}
            </span>
          </div>

          <!-- <div class="search">
            <el-autocomplete ref="search" v-model="search" :fetch-suggestions="querySearch" placeholder="请输入关键词搜1索" prefix-icon="el-icon-search" clearable style="width: 270px;"></el-autocomplete>
          </div> -->
          <div class="search" ref="search">
            <div v-for="(item, index) in inputArray" :key="index">
              <el-input
                v-model="item.value"
                placeholder="关键词搜索"
                prefix-icon="el-icon-search"
                clearable
                @input="onInput(index)"
                @keydown.esc="onEscKey"
                ref="searchInput"
              ></el-input>
            </div>
          </div>
          <div class="btn">
            <el-button type="primary" @click="get_data_apiall">
              <i class="el-icon-refresh-right"></i> 获取数据
            </el-button>
          </div>

          <el-dropdown
            class="btn hidden-md-and-down"
            @command="handleDropdownCommand"
            trigger="click"
          >
            <el-button type="primary" class="el-dropdown-link">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="sync">批量同步</el-dropdown-item>
              <el-dropdown-item command="reset">批量重置</el-dropdown-item>
              <el-dropdown-item command="add">添加卡片</el-dropdown-item>
              <el-dropdown-item command="clear">清空链接</el-dropdown-item>
              <el-dropdown-item command="copySkuId"
                >复制SKU ID</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>

          <div class="btn hidden-md-and-down">
            <el-button :type="buttonType_2" @click="setUpButton">
              <i class="el-icon-s-tools"></i> 设置
            </el-button>
          </div>

          <!-- 日期范围选择器 -->
          <div class="head_date hidden-md-and-down">
            <el-date-picker
              v-model="dateRange"
              type="daterange"
              :picker-options="pickerOptions"
              placeholder="选择日期范围"
              @change="filterByDateRange"
              boolean
            ></el-date-picker>
          </div>
        </el-header>

        <!-- 主要内容区域 -->
        <el-container class="container_con">
          <el-main class="main" v-infinite-scroll="new_list_num_plus">
            <div v-show="gu_list.length != 0" class="car_gu">
              <el-header
                style="
                  display: flex;
                  height: 300px;
                  padding: 0;
                  overflow-x: auto;
                  padding-top: 8px;
                  flex-wrap: wrap;
                  overflow-y: scroll;
                  scroll-snap-type: y mandatory;
                  scroll-padding: 8px;
                "
                class="card_gu"
              >
                <CardGu
                  v-for="item in gu_list"
                  :key="item.id"
                  :data="item"
                ></CardGu>
              </el-header>
            </div>
            <el-main>
              <div class="list_card" v-show="new_list.length != 0">
                <Card
                  :id="`card-${item.id}`"
                  v-for="item in new_list"
                  :key="item.id"
                  :ref="`card-${item.id}`"
                  :data="item"
                  :BinImg="BinImg"
                  :ActionsDis="ActionsDis"
                  :BinImg_num="newBinImg_num"
                  @capture="takeScreenshot"
                  @triggerUpdateId="show_Id"
                  @update-data="get_data_apiall"
                ></Card>
              </div>
            </el-main>
          </el-main>
          <!-- 侧边栏 -->
          <!-- isSidebarVisible -->
          <el-aside v-show="true" class="aside hidden-md-and-down">
            <div class="oPerBtn" v-if="false">
              <el-button @click="handleViolationClick">已违规</el-button>
            </div>
            <el-tabs v-model="activeName" @tab-click="handleClick" stretch>
              <el-tab-pane name="kanban">
                <template slot="label">
                  <span> 看板 </span>
                </template>
                <div class="card_kanban scroll">
                  <SideCard
                    v-for="item in kanban_list"
                    :key="item.id"
                    :data="item"
                    @show_id="get_show_id"
                    @hide_id="get_hide_id"
                    @GetDataSearch="upDataSearch"
                  ></SideCard>
                </div>
              </el-tab-pane>
              <!-- <el-tab-pane label="链接提示" name="badge">
                <el-image
                  class="card_img"
                  style="max-height: 50px; height: 50px;"
                  :src='"https://pic.rmb.bdstatic.com/bjh/5eba9634898886c5650982b3d0ef22ab.png"'
                  v-on:dblclick="up_isx($event)"
                >
                链接提示
                {{this.isbadge_list}}
                </el-image>
              </el-tab-pane> -->
              <el-tab-pane name="production" class="production">
                <template slot="label">
                  <span class="padding-right-class"> 生产单 </span>
                </template>
                <div class="production_head">
                  <el-input
                    placeholder="请输入款号 用户名关键词"
                    v-model="production.search"
                    class="input-with-select"
                    ref="production_input"
                    autofocus="production_focus"
                    clearable
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="productionsearch"
                    ></el-button>
                  </el-input>
                </div>
                <div class="production_list" v-if="production.list.length">
                  <div
                    v-for="item in production.list"
                    :key="item.id"
                    :class="
                      item.isNo == 1
                        ? 'production_item'
                        : 'production_item production_clear'
                    "
                  >
                    <div class="production_text">
                      <div>
                        {{ item.year }} {{ item.uid }} {{ item.user }}
                        {{ item.zhu }}
                      </div>
                      <div class="flex">
                        <div class="r5">{{ item.title }} {{ item.color }}</div>
                        <div>{{ item.num }}</div>
                      </div>
                    </div>
                    <div class="production_img">
                      <el-image
                        fit="scale-down"
                        style="width: 100%; height: 100%"
                        :src="
                          item.img
                            ? item.img + '.jpg'
                            : 'https://pic.rmb.bdstatic.com/bjh/5eba9634898886c5650982b3d0ef22ab.png'
                        "
                        :preview-src-list="[item.img]"
                      ></el-image>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <el-empty description="暂无内容~"></el-empty>
                </div>
              </el-tab-pane>
              <el-tab-pane name="kdzs" class="production kdzs">
                <template slot="label">
                  <span class="padding-right-class"> 快递组手 </span>
                </template>
                <Beihuodan />
              </el-tab-pane>
            </el-tabs>
          </el-aside>

          <div class="sidebar-icon cur nox" v-if="false" @click="toggleSidebar">
            <!-- 图标内容 -->
            <i class="iconfont">&#xeb10;</i>
          </div>
        </el-container>
      </el-container>
    </div>

    <!-- 弹出框开始 -->

    <!-- 修改ID 弹框 -->
    <el-dialog title="修改ID" :visible.sync="UpdateIdVisible">
      <el-input
        v-model="card_data.commodity_id"
        style="margin-bottom: 10px"
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uptateId">确 定</el-button>
        <el-button @click="UpdateIdVisible = false">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 添加卡片 -->
    <el-dialog
      title="添加卡片"
      :visible.sync="numRows_dialogVisible"
      width="30%"
    >
      <el-input
        v-model="numRows"
        placeholder="请输入要添加的行数"
        ref="numRows_input"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="numRows_dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="create_data">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加卡片结束 -->

    <!-- 截图 -->
    <el-dialog
      :title="!this.canvasHTML ? '截图中···' : '完成'"
      :visible.sync="isDialogVisible"
      @opened="opened_takeScreenshot"
      @closed="close_takeScreenshot"
    >
      <div
        class="take_div"
        v-loading="!canvasHTML"
        element-loading-background="rgba(0, 0, 0, 0.2)"
      >
        <div v-show="canvasHTML" class="take" ref="canvasHTML"></div>
        <div v-show="!canvasHTML" class="take" ref="take">
          <CardTake :cardData="cardData"></CardTake>
        </div>
      </div>
    </el-dialog>

    <!-- 设置 -->
    <el-dialog title="设置" :visible.sync="ToolsVisible">
      <div style="margin-bottom: 10px">
        修改图片大小
        <el-slider
          :min="90"
          :max="300"
          v-model="BinImg_num"
          @change="up_Bing_num"
          :step="30"
          show-stops
          show-input
        >
        </el-slider>
      </div>
      <div style="margin-bottom: 10px">
        操作开关
        <el-switch
          v-model="ActionsDis"
          @change="up_ActionsDis"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>
      <div style="margin-bottom: 10px">
        关闭文本
        <el-switch
          v-model="text_Vis"
          @change="up_text_Vis"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>
      <div>
        <div class="tokens flex">
          <div
            v-for="(token, index) in tokens"
            :key="index"
            class="token-item"
            @click="setToken('douyintoken', token.token)"
          >
            {{ token.token }}
          </div>
        </div>
        <el-form>
          <el-form-item label="抖音抖店">
            <div class="flex">
              <el-input v-model="douyinId" placeholder="抖音ID"></el-input>
              <el-input v-model="douyincookie" autocomplete="off"></el-input>
              <!-- <el-button type="primary" @click="setToken('douyintoken',douyincookie)">提交</el-button> -->
              <!-- <el-button :type="buttonType" @click="sendDouyinIdRequest">提交</el-button> -->
              <el-button
                :type="buttonType"
                @click="setToken('douyintoken', douyincookie)"
                >提交</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="快递组手">
            <div class="flex">
              <el-input
                v-model="expressInventory"
                placeholder="快递组手关键词"
              ></el-input>
              <el-input v-model="kdzstoken" autocomplete="off"></el-input>
              <el-button
                :type="buttonType_1"
                @click="setToken('kdzstoken', kdzstoken)"
                >提交</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 弹出框End -->
  </div>
</template>

<script>
import Card from "../components/Card";
import CardGu from "../components/CardGu";
import SideCard from "../components/SideCard";
import Coffee from "../components/Coffee";
import querystring from "querystring";
import Com404 from "../components/Com404";
import Beihuodan from "@/components/more/beihuodan.vue";
import html2canvas from "html2canvas";
import CardTake from "@/components/CardTake.vue";
// import ClipboardComponent from '@/components/ClipboardComponent.vue'

export default {
  name: "HomeView",
  data() {
    return {
      UpdateIdVisible: false, //修改id
      card_data: {},
      tokens: [],

      dateRange: [], // 日期范围
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick: () => {
              const endDate = new Date();
              const startDate = new Date();
              endDate.setDate(endDate.getDate() + 1); // 明天
              this.dateRange = [startDate, endDate];
            },
          },
          {
            text: "昨天",
            onClick: () => {
              const endDate = new Date();
              const startDate = new Date();
              endDate.setDate(endDate.getDate()); // 今天
              startDate.setDate(startDate.getDate() - 1); // 昨天
              this.dateRange = [startDate, endDate];
            },
          },
          {
            text: "最近三天",
            onClick: () => {
              const endDate = new Date();
              const startDate = new Date();
              startDate.setDate(startDate.getDate() - 2); // 3天前
              this.dateRange = [startDate, endDate];
            },
          },
          {
            text: "最近一周",
            onClick: () => {
              const endDate = new Date();
              const startDate = new Date();
              startDate.setDate(startDate.getDate() - 7);
              this.dateRange = [startDate, endDate];
            },
          },
          {
            text: "最近一个月",
            onClick: () => {
              const endDate = new Date();
              const startDate = new Date();
              startDate.setMonth(startDate.getMonth() - 1);
              this.dateRange = [startDate, endDate];
            },
          },
          {
            text: "最近三个月",
            onClick: () => {
              const endDate = new Date();
              const startDate = new Date();
              startDate.setMonth(startDate.getMonth() - 3);
              this.dateRange = [startDate, endDate];
            },
          },
          {
            text: "最近一年",
            onClick: () => {
              const endDate = new Date();
              const startDate = new Date();
              startDate.setFullYear(startDate.getFullYear() - 1);
              this.dateRange = [startDate, endDate];
            },
          },
          {
            text: "最近二年",
            onClick: () => {
              const endDate = new Date();
              const startDate = new Date();
              startDate.setFullYear(startDate.getFullYear() - 2);
              this.dateRange = [startDate, endDate];
            },
          },
        ],
      },
      numRows: 1, // 存储用户输入的行数
      numRows_dialogVisible: false,
      visible: false,
      loading_title: "数据加载中~",
      isSidebarVisible:
        localStorage.getItem("sidebarVisible") !== null
          ? localStorage.getItem("sidebarVisible") === "true"
          : false,
      /** 搜索 */
      inputArray: [{ value: "" }],
      search: "",
      search_num: 20,
      search_time: null,
      search_time_arr: null,
      restaurants: [],
      users: 0,
      list: [],
      keep_arr: [],
      new_list_len: 0,
      new_list: [],
      new_list_p: [],
      new_list_num: 1,
      new_list_num_time: null,
      kanban_list: [], //显示看板的数据
      gu_list: [], //固定看板的数据
      isbadge_list: [], //提示的数据
      msgList: [],
      isnew_list: true,
      // 需要显示货隐藏的id
      card_data: {},
      // 设置
      ToolsVisible: false,
      douyincookie: "",
      kdzstoken: "",
      BinImg: false, //大图模式
      BinImg_num: 90,
      newBinImg_num: 90,
      ActionsDis: true, //操作开关
      user: {
        username: "",
      },
      // 生产单表格
      production: {
        search: "",
        production_focus: false,
        list: [],
      },
      activeName: "kanban", //切换
      text_content: "", //经典语录
      text_Vis: true,
      /** 截图 */
      isDialogVisible: false,
      screenshot: null,
      screenshot_id: 0,
      cardData: null,
      canvasHTML: null,
      /** 剪切板 */
      jianqie: {
        IsNo: false,
        list: [],
        search: "",
        len: true,
      },
      douyinId: "", // 初始化抖音ID为空字符串
      axiosResult: 0,
      expressInventory: "", // 初始化快递组手库存为空字符串
      axiosResult_1: 0,
      ToolsVisible: false,
    };
  },
  components: {
    Card,
    SideCard,
    CardGu,
    Coffee,
    Com404,
    Beihuodan,
    CardTake,
  },
  watch: {
    search: {
      handler(newVal, oldVal) {
        this.new_list_num = 1;
        this.new_list_fn();
      },
    },
    // 监听属性变化，将新值保存到 localStorage 中
    douyinId(newValue) {
      localStorage.setItem("douyinId", newValue);

      if (newValue) {
        this.sendDouyinIdRequest();
      }
    },
    expressInventory(newValue) {
      localStorage.setItem("expressInventory", newValue);

      if (newValue) {
        this.sendExpressInventoryRequest();
      }
    },
  },
  activated() {
    this.text_lu();
    this.keyEvent();
    let savedResults = localStorage.getItem("restaurants");
    if (savedResults) {
      this.restaurants = JSON.parse(savedResults);
    }
  },
  computed: {
    buttonType() {
      return this.axiosResult == 0 ? "warning" : "primary";
    },
    buttonType_1() {
      return this.axiosResult_1 === 0 ? "warning" : "primary";
    },
    buttonType_2() {
      if (this.axiosResult === 0 || this.axiosResult_1 === 0) {
        return "warning";
      } else {
        return "primary";
      }
    },
  },
  async mounted() {
    this.init_img();
    this.get_data_api();
    this.douyinId = localStorage.getItem("douyinId") || "";
    this.expressInventory = localStorage.getItem("expressInventory") || "";

    setTimeout(() => {
      this.$socket.emit("get_users");
      //接收服务端的信息
      this.socketsEvent();
    }, 1000);
  },
  methods: {
    /**
     * 打开设置
     */
    setUpButton() {
      this.ToolsVisible = true; // 显示工具
      this.$axios({
        method: "post",
        url: "/api/kanban/getDouyinTokens",
        data: {
          id: this.card_data.id,
          commodity_id: this.card_data.commodity_id,
          beiid: this.card_data.beiid,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "请求成功",
              type: "success",
              duration: 1000,
            });
            // console.log(res.data.data); // 处理返回的数据
            this.tokens = res.data.data;
          }
        })
        .catch((error) => {
          this.$message({
            message: "请求失败",
            type: "error",
            duration: 1000,
          });
          console.error(error);
        });
    },

    /** 违规按钮 */
    handleViolationClick() {
      // violation
      // 发送违规 socket
      this.$socket.emit("violation", "违规了");
    },
    /* 违规结束 */

    // 修改id
    show_Id(data) {
      this.card_data = data;
      this.UpdateIdVisible = true;
    },
    // 修改id 方法
    uptateId() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/commodity_id",
        data: {
          id: this.card_data.id,
          commodity_id: this.card_data.commodity_id,
          beiid: this.card_data.beiid,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          this.UpdateIdVisible = false;
          this.$socket.emit("commodity_id", res.data.data);
        }
      });
    },

    /** 多个输入框 开始 */
    onInput(index) {
      // 如果输入框中有内容，添加新的输入框
      if (
        this.inputArray[index].value.trim() !== "" &&
        index === this.inputArray.length - 1
      ) {
        this.addInput();
      }

      // 如果当前输入框为空，且不是唯一的一个输入框，删除当前输入框
      if (
        this.inputArray[index].value.trim() === "" &&
        this.inputArray.length > 1
      ) {
        this.removeInput(index);
      }
      if (this.inputArray.length == 1 && this.inputArray[0].value == "") {
        this.new_list_num = 1;
      }
      this.new_list_num = 1;

      this.new_list_fn();
    },
    filterResults() {
      let results = this.list;
      let results_all = [];
      let lowerSearchArray = this.inputArray.map((item) =>
        item && item.value && typeof item.value === "string"
          ? item.value.trim().toLowerCase()
          : ""
      );
      // 只在有多个值时才过滤空字符串
      if (this.inputArray.length > 1) {
        lowerSearchArray = lowerSearchArray.filter(
          (searchTerm) => searchTerm !== ""
        );
      }
      if (lowerSearchArray.length > 1) {
        let res1 = this.search_arr(
          results,
          lowerSearchArray[0],
          this.dateRange,
          true
        );
        for (let i = 0; i < lowerSearchArray.length; i++) {
          res1 = this.search_arr(
            res1,
            lowerSearchArray[i],
            this.dateRange,
            true
          );
          results_all.push(res1);
        }
        results = res1;
      } else {
        for (let i = 0; i < lowerSearchArray.length; i++) {
          results = this.search_arr(results, lowerSearchArray[i]);
        }
      }

      this.new_list = results.slice(0, 40 * this.new_list_num);
    },

    addInput() {
      if (this.inputArray.length < 6) {
        this.inputArray.push({ value: "" }); // 添加新的输入框
      }
    },
    removeInput(index) {
      // 确保至少保留一个输入框
      if (this.inputArray.length > 1) {
        this.inputArray.splice(index, 1); // 删除对应的输入框
      }
    },
    /** 多个输入框 结束 */
    // 自动快递组手是否过期
    async sendExpressInventoryRequest() {
      try {
        const response = await this.$axios.get(
          `/api/kanban/kucunPageList?skuOuterId=${this.expressInventory}`
        );
        // 处理响应
        if (response.data.pageNo === 1) {
          this.axiosResult_1 = 1;
        }
      } catch (error) {
        console.error("Axios 请求失败", error);
      }
    },
    // 自动获取抖音是否过期
    async sendDouyinIdRequest() {
      try {
        const response = await this.$axios.get(
          `/api/kanban/douyinku?id=${this.douyinId}`
        );
        if (response.data === 1) {
          this.axiosResult = 1;
        }
      } catch (error) {
        console.error("Axios 请求失败", error);
      }
    },
    filterByDateRange() {
      // 确保 this.dateRange 不为 null 或 undefined
      if (!this.dateRange) {
        return;
      }
      // 获取日期范围的开始日期和结束日期
      let startDate = this.dateRange[0];
      let endDate = this.dateRange[1];

      // 如果两个日期相同，将第二个日期设为当天的第二天日期
      if (
        startDate &&
        endDate &&
        startDate.toDateString() === endDate.toDateString()
      ) {
        endDate = new Date(endDate);
        endDate.setDate(endDate.getDate() + 1); // 第二天日期
      }

      // 使用筛选函数筛选日期范围内的数据
      this.new_list = this.search_arr(this.list).filter((item) => {
        const updateTime = new Date(item.updateTime);
        return (
          (!startDate || updateTime >= startDate) &&
          (!endDate || updateTime <= endDate)
        );
      });
    },
    filterByDateRange() {
      // 确保 this.dateRange 不为 null 或 undefined
      if (!this.dateRange) {
        return;
      }

      // 获取日期范围的开始日期和结束日期
      let startDate = this.dateRange[0];
      let endDate = this.dateRange[1];

      // 如果两个日期相同，将第二个日期设为当天的第二天日期
      if (
        startDate &&
        endDate &&
        startDate.toDateString() === endDate.toDateString()
      ) {
        endDate = new Date(endDate);
        endDate.setDate(endDate.getDate() + 1); // 第二天日期
      }

      // 使用筛选函数筛选日期范围内的数据
      this.new_list = this.search_arr(this.list).filter((item) => {
        const updateTime = new Date(item.updateTime);
        return (
          (!startDate || updateTime >= startDate) &&
          (!endDate || updateTime <= endDate)
        );
      });
    },

    // 批量同步
    async batchSync() {
      const resultObject = {
        uniqueSplitValues: [],
        bannumInfo: [],
      };

      for (const item of this.new_list) {
        if (item.inventory_text !== null && item.inventory_text !== "") {
          const inventoryTextValues = item.inventory_text.includes("、")
            ? item.inventory_text.split("、")
            : [item.inventory_text];
          resultObject.uniqueSplitValues.push(...inventoryTextValues);
        }

        for (const bannum of item.bannum) {
          if (bannum.sysItemSkuId !== null && bannum.sysItemSkuId !== "") {
            resultObject.bannumInfo.push({
              bannumid: bannum.bannumid,
              sysItemSkuId: bannum.sysItemSkuId,
            });
          }
        }
      }

      if (resultObject.uniqueSplitValues.length == 0) {
        this.$message.error("没有同步的数据,请检查是否设置skuid");
        return;
      }

      const res = await this.$axios.post(
        "/api/kanban/synchronizeInventory_quan",
        resultObject
      );

      if (res.data.success === true) {
        await this.get_data();
        this.$notify({
          title: "成功",
          message: `批量同步成功 本次修改了 ${res.data.num} 条数据~`,
          type: "success",
          position: "top-left",
          duration: 0,
        });
      }
    },
    // 批量重置
    batchReset() {
      const bannumIds = this.new_list.flatMap((item) =>
        item.bannum
          .filter(
            (bannum) =>
              bannum.isno === 1 &&
              ((bannum.onnum !== null && bannum.onnum !== "") ||
                (bannum.disnum !== null && bannum.disnum !== ""))
          )
          .map((bannum) => bannum.bannumid)
      );

      if (bannumIds.length == 0) {
        this.$message.warning("无须重置");
        return;
      }

      this.$axios
        .post("/api/kanban/batchUpNumClear", bannumIds)
        .then(async (res) => {
          const modifiedCount = res.data;
          if (modifiedCount === bannumIds.length) {
            await this.get_data();
            this.$message.success(
              `批量重置成功 本次修改了 ${modifiedCount} 条数据~`
            );
          }
        });
    },
    // 更多操作_下拉
    handleDropdownCommand(command) {
      // 根据不同的命令执行相应的操作
      switch (command) {
        case "sync":
          this.batchSync();
          break;
        case "reset":
          this.batchReset();
          break;
        case "add":
          this.numRows_openDialog();
          break;
        case "clear":
          this.clear_ban_url();
          break;
        case "copySkuId":
          this.copySkuId();
          break;
        default:
          break;
      }
    },
    keepOnlyDigits(str) {
      return str.replace(/\D/g, "");
    },
    // 剪切板
    handleContentChanged(newVal) {
      // this.jianqie.len = true;
      // this.jianqie.search = newVal;
      // this.jianqie.list = this.search_arr(this.list, newVal);
      // if (this.jianqie.list.length >= 8) {
      //   this.jianqie.list.length = 8;
      //   this.jianqie.len = false;
      // }
      // if (this.jianqie.list.length > 0) {
      //   this.jianqie.IsNo = true;
      // }
    },
    focusOnButton() {
      this.$refs.jianqieBtn.$el.focus();
    },
    pasteFromClipboard() {
      this.jianqie.IsNo = false;
      if (this.jianqie.len) {
        this.new_list = this.jianqie.list;
      } else {
        this.search = this.jianqie.search;
      }
      this.$message.success("已自动筛选剪切板内容");
    },
    // 截图
    takeScreenshot(id) {
      this.screenshot_id = id;
      this.cardData = this.new_list.find(
        (item) => item.id === this.screenshot_id
      );
      this.isDialogVisible = true;
    },
    opened_takeScreenshot() {
      html2canvas(this.$refs["take"], {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        this.$refs["canvasHTML"].innerHTML = "";
        this.$refs["canvasHTML"].appendChild(canvas);
        this.canvasHTML = true;
        this.$message.success("成功,右键复制即可");
      });
    },
    close_takeScreenshot() {
      this.$refs["canvasHTML"].innerHTML = "";
      this.canvasHTML = false;
      this.cardData = {
        url: "",
        basic: "",
        text: "",
        commodity_id: "",
        bannums: [],
        bannum: [],
      };
    },
    // 添加卡片输入框
    numRows_openDialog() {
      this.numRows_dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.numRows_input.focus();
      });
    },
    // 初始化图片大小
    init_img() {
      this.BinImg = localStorage.getItem("BinImg") == "true" ? true : false;
      this.ActionsDis =
        localStorage.getItem("ActionsDis") == "false" ? false : true;
      this.BinImg_num = localStorage.getItem("BinImg_num")
        ? Number(localStorage.getItem("BinImg_num"))
        : 90; //获取图片默认大小
      this.text_Vis =
        localStorage.getItem("text_Vis") == "false" ? false : true; //文本开关

      // 图片默认大小
      this.newBinImg_num = this.BinImg_num;
    },
    // 显示隐藏侧边栏
    toggleSidebar() {
      // 切换侧边栏的可见性
      this.isSidebarVisible = !this.isSidebarVisible;

      // 将新的状态保存到 localStorage 中
      localStorage.setItem("sidebarVisible", this.isSidebarVisible);
    },
    // 键盘事件
    keyEvent() {
      // 键盘事件
      document.onkeydown = (e) => {
        if (event.ctrlKey && event.key === "j") {
          event.preventDefault();
          this.up_isx(this.new_list[0]);
        }
        if (event.ctrlKey && event.key === "k") {
          event.preventDefault();
          this.up_isx(this.kanban_list[0]);
        }
        if (e.keyCode == 27) {
          // 默认esc获取焦点
          // this.$refs.search.focus();
          // this.$refs.search.$refs.input.select();
          this.$refs.searchInput[0].focus();
          this.$refs.searchInput[0].$refs.input.select();
        }
        // if(e.keyCode == 75 && e.ctrlKey){
        //   this.production.t = true;
        //   setTimeout(()=>{
        //     this.$refs.production_input.focus();
        //     this.$refs.production_input.select();
        //   },100)
        //   e.preventDefault();
        //   return;
        // }
      };
    },
    // 修改是否到看板
    up_isx(data) {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/up_data_isx",
        data: {
          id: data.id,
          isx: data.isx,
        },
      }).then((res) => {
        if (res.data.code == 1 || res.data == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: 1000,
          });
          this.$socket.emit("up_isx");
        }
      });
    },
    // 接收服务器事件
    socketsEvent() {
      this.$socket.on("get_data_one", (res) => {
        let indexId = this.list.findIndex((item) => {
          if (item.id == res.id) {
            return true;
          }
        });
        this.list[indexId].bannum.forEach((item) => {
          res.bannum.forEach((item1) => {
            if (item1.bannumid == item.bannumid) {
              item.disnum = item1.disnum;
              item.surnum = item1.surnum;
            }
          });
        });
        // this.new_list = this.search_arr(this.list);
        this.filterResults();
        this.$message.success("修改成功");
      });

      this.$socket.on("data", (data) => {
        if (this.loading_title != "暂无内容~") {
          this.loading_title = "暂无内容~";
        }
        this.data_init(data);
      });

      // 获取规格发生改变的数据
      this.$socket.on("get_table_guige_data", (res) => {
        let { data } = res;
        let index = this.list.findIndex((item) => item.id == res.id);
        this.list[index].bannum = data;
        this.$set(this.list[index], "bannum", data);
      });

      // 接收表格数据
      this.$socket.on("get_table_data", (res) => {
        // {bannumid: 15, banid: 291, color: '日落黄'
        let { data } = res;
        if (data) {
          data = data[0];
        }
        let index1 = this.list.findIndex((item) => item.id == data.banid);
        let index2 = this.list[index1].bannum.findIndex(
          (item) => item.bannumid == data.bannumid
        );
        this.list[index1].bannum[index2][res.type] = data[res.type];
        this.$set(this.list[index1].bannum[index2], res.type, data[res.type]);
        if (res.type == "paixu") {
          this.list[index1].bannum = this.list[index1].bannum.sort((a, b) => {
            return b.paixu - a.paixu;
          });
        }
        if (res.type == "bag") {
          this.list[index1].bannum[index2].bag =
            this.list[index1].bannum[index2].bag == 1 ? true : false;
        }
      });

      // 接收表格清空的数据
      this.$socket.on("get_num_clear", (res) => {
        let { data } = res;
        let index1 = this.list.findIndex((item) => item.id == res.id);
        data.forEach((item) => {
          let index = this.list[index1].bannum.findIndex(
            (item1) => item.bannumid == item1.bannumid
          );
          this.list[index1].bannum[index].onnum = item.onnum;
          this.list[index1].bannum[index].disnum = item.disnum;
          this.$set(this.list[index1].bannum[index], "onnum", item.onnum);
          this.$set(this.list[index1].bannum[index], "disnum", item.disnum);
        });
      });

      // 接收固定看板信息
      this.$socket.on("get_isg_data", (data) => {
        this.list.map((item) => {
          return (item.isg = 0);
        });
        if (data.length > 0) {
          this.list.forEach((item, index) => {
            data.forEach((item1) => {
              if (item.id == item1.id) {
                this.list[index].isg = item1.isg;
                this.$set(this.list[index], "isg", item.isg);
              }
            });
          });
        } else {
          this.list.map((item) => (item.isg = 0));
        }
        this.list.map((item) => {
          item.isg_t = item.isg == 1 ? true : false;
        });
        this.gu_list = this.list.filter((item) => item.isg === 1);
      });

      // 接收提示的数据
      this.$socket.on("get_isbadge_data", (data) => {
        this.list.map((item) => {
          return (item.isbadge = 0);
        });
        if (data.length > 0) {
          this.list.forEach((item, index) => {
            data.forEach((item1) => {
              if (item.id == item1.id) {
                this.list[index].isbadge = item1.isbadge;
                this.$set(this.list[index], "isbadge", item.isbadge);
                this.$set(
                  this.list[index],
                  "isbadge_t",
                  item.isbadge == 1 ? true : false
                );
              }
            });
          });
        } else {
          this.list.map((item) => (item.isbadge = 0));
        }
        // this.list.map(item => {
        //   return item.isbadge_t = item.isbadge == 1 ? true : false;
        // })
        this.isbadge_list = this.list.filter((item) => item.isbadge === 1);
      });

      // 接收修改id
      // commodity_id
      this.$socket.on("commodity_id", (data) => {
        let index = this.list.findIndex((item) => item.id == data.id);
        this.list[index].commodity_id = data.commodity_id;
        this.$set(this.list[index], "commodity_id", data.commodity_id);
      });

      // 接收排序信息
      // get_paixu_data
      this.$socket.on("get_paixu_data", (data) => {
        data.forEach((item) => {
          let index = this.list.findIndex((e) => e.id == item.id);
          if (index > -1) {
            this.list[index].xnum = item.xnum;
            this.$set(this.list[index], "xnum", item.xnum);
          }
        });
        this.list.sort((a, b) => {
          return a.xnum - b.xnum;
        });
        this.new_list_fn();
      });

      // 接收看板信息
      this.$socket.on("get_isx", (data) => {
        /**
         * isx_num 看板顺序
         * link_num 看板链接
         */
        this.list.map((item) => {
          return (item.isx = 0);
        });
        if (data.length > 0) {
          this.list.forEach((item, index) => {
            data.forEach((item1) => {
              if (item.id == item1.id) {
                this.list[index].isx = item1.isx;
                this.list[index].isx_num = item1.isx_num;
                this.list[index].link_num = item1.link_num;
                this.$set(this.list[index], "isx", item.isx);
                this.$set(this.list[index], "isx_num", item.isx_num);
                this.$set(this.list[index], "link_num", item.link_num);
              }
            });
          });
        } else {
          this.list.map((item) => (item.isx = 0));
        }
        this.list.map((item) => {
          item.isx_t = item.isx == 1 ? true : false;
        });
        this.kanban_list = this.list.filter((item) => item.isx === 1);
        this.kanban_list.sort((a, b) => {
          return a.isx_num - b.isx_num;
        });
      });

      // 接收修改图片
      this.$socket.on("get_img_data", (data) => {
        const index = this.list.findIndex((item) => item.id == data[0].id);
        this.$set(this.list[index], "url", data[0].url);
      });

      // 接收修改的基本信息
      this.$socket.on("get_basic_data", (data) => {
        const index = this.list.findIndex((item) => item.id == data[0].id);
        this.$set(this.list[index], "basic", data[0].basic);
      });

      // 接收修改的文本
      this.$socket.on("get_text_data", (data) => {
        const index = this.list.findIndex((item) => item.id == data[0].id);
        this.$set(this.list[index], "text", data[0].text);
        this.$set(this.list[index], "text_jiu", data[0].text);
      });

      // 接收开始倒计时 get_counttime
      this.$socket.on("get_counttime", (data) => {
        this.list.map((item) => {
          item.counttime = 0;
        });
        const index = this.list.findIndex((item) => item.id == data.id);
        this.$set(this.list[index], "counttime", data.counttime);
        this.$set(
          this.list[index],
          "counttime_t",
          data.counttime == 1 ? true : false
        );
      });

      this.$socket.on("users", (data) => {
        this.users = data;
      });

      this.$socket.on("tui_user", (data) => {
        this.users = data;
      });
    },
    //切换
    handleClick(tab, event) {},
    // 获取搜索id
    upDataSearch(data) {
      this.inputArray[0].value = data;
    },
    // 修改图片大小
    up_Bing_num() {
      localStorage.setItem("BinImg_num", this.BinImg_num);
      this.newBinImg_num = this.BinImg_num;
    },
    // 操作开关
    up_ActionsDis() {
      localStorage.setItem("ActionsDis", this.ActionsDis);
    },
    // 设置大图模式
    up_BinImg() {
      localStorage.setItem("BinImg", this.BinImg);
    },
    data_init(data) {
      data.map((item) => {
        // item.kdzs.kc.list = [];
        item.kdzs = {
          kc: {
            list: [],
          },
        };
        item.text_jiu = item.text;
        item.isx_t = item.isx == 1 ? true : false;
        item.isg_t = item.isg == 1 ? true : false;
        item.isbadge_t = item.isbadge == 1 ? true : false;
        item.bannum.forEach((item1) => {
          item1.bag = item1.bag == 1 ? true : false;
        });
      });
      this.list = data;
      this.kanban_list = data.filter((item) => item.isx === 1);
      this.kanban_list.sort((a, b) => {
        return a.isx_num - b.isx_num;
      });
      this.gu_list = data.filter((item) => item.isg === 1);
      this.isbadge_list = data.filter((item) => item.isbadge === 1);
      this.filterResults();
    },
    // 处理接收到的单个数据
    one_data(data) {
      let index = this.list.findIndex((item) => item.id == data.id);
      this.$set(this.list, index, data);
      this.data_init(this.list);
    },
    // 通过api获取最新数据
    get_data_api() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/data",
      })
        .then((res) => {
          this.data_init(res.data);
        })
        .catch((err) => {
          this.commonApi.clearToken();
        });
    },
    // 通过api获取最新数据
    get_data_apiall() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/dataall",
      })
        .then((res) => {
          this.data_init(res.data);
          this.$message.success("获取所有数据成功");
        })
        .catch((err) => {
          this.commonApi.clearToken();
        });
    },
    // 数量加 1;
    new_list_num_plus() {
      clearTimeout(this.new_list_num_time);
      if (this.new_list_num >= Math.ceil(this.list.length / this.search_num)) {
        return;
      }
      this.new_list_num_time = setTimeout(() => {
        this.new_list_num = this.new_list_num + 1;
        this.new_list_fn();
      }, 300);
    },
    // 关键词搜索
    new_list_fn(value = "") {
      // this.new_list_num = 1;
      clearTimeout(this.search_time);
      this.search_time = setTimeout(() => {
        this.filterResults();
      }, 300);
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    // 筛选函数
    // search_arr(arr,value=this.search) {
    //   let lowerSearch = value.trim().toLowerCase();
    //   if (lowerSearch) {
    //     let newResult = { "value": lowerSearch };

    //     let exists = this.restaurants.some(restaurant => restaurant.value.toLowerCase() === newResult.value.toLowerCase());

    //     if (!exists) {
    //       this.restaurants.unshift(newResult);
    //       this.restaurants = this.restaurants.slice(0, 5);
    //       localStorage.setItem('restaurants', JSON.stringify(this.restaurants));
    //     }
    //   }

    //   let itemContainsSearch = item =>
    //     ['basic', 'text', 'commodity_id', 'beiid'].some(prop =>
    //       (item[prop] || "").toLowerCase().includes(lowerSearch)
    //     );

    //   let bannumContainsSearch = bannum_item => {
    //     let propertiesToSearchIn = ['money', 'color'].map(prop => bannum_item[prop] || '');
    //     if(this.search.trim() == "未清零"){
    //       return (bannum_item['onnum'] || '') != '' || (bannum_item['disnum'] || '') != '';
    //     }
    //     return propertiesToSearchIn.some(prop => prop.toLowerCase().includes(lowerSearch));
    //   };

    //   let arr1 = arr.filter(item => {
    //     item.commodity_id = item.commodity_id || "";
    //     item.basic = item.basic || "";
    //     let bannumNonEmpty = item.bannum.some(bannumContainsSearch);
    //     return bannumNonEmpty || itemContainsSearch(item);
    //   });

    //   this.new_list_len = arr1.length;
    //   return arr1.length > this.search_num ? arr1.slice(0, this.search_num * this.new_list_num) : arr1;
    // },
    // 筛选函数
    search_arr(
      arr,
      value = this.search,
      dateRange = this.dateRange,
      all = false
    ) {
      let lowerSearch = value.trim().toLowerCase();

      if (lowerSearch) {
        let newResult = { value: lowerSearch };

        let exists = this.restaurants.some(
          (restaurant) =>
            restaurant.value.toLowerCase() === newResult.value.toLowerCase()
        );

        if (!exists) {
          this.restaurants.unshift(newResult);
          this.restaurants = this.restaurants.slice(0, 5);
          localStorage.setItem("restaurants", JSON.stringify(this.restaurants));
        }
      }

      let itemContainsSearch = (item) =>
        ["basic", "text", "commodity_id", "beiid"].some((prop) =>
          (item[prop] || "").toLowerCase().includes(lowerSearch)
        );

      let bannumContainsSearch = (bannum_item) => {
        let propertiesToSearchIn = ["money", "color"].map(
          (prop) => bannum_item[prop] || ""
        );
        if (value.trim() === "未清零") {
          return (
            (bannum_item["onnum"] || "") !== "" ||
            (bannum_item["disnum"] || "") !== ""
          );
        }
        return propertiesToSearchIn.some((prop) =>
          prop.toLowerCase().includes(lowerSearch)
        );
      };

      let arr1 = arr.filter((item) => {
        item.commodity_id = item.commodity_id || "";
        item.basic = item.basic || "";
        let bannumNonEmpty = item.bannum.some(bannumContainsSearch);
        return bannumNonEmpty || itemContainsSearch(item);
      });

      // 如果传递了日期范围参数，则根据日期范围进行筛选
      if (dateRange && dateRange.length === 2) {
        const [startDate, endDate] = dateRange;
        arr1 = arr1.filter((item) => {
          const updateTime = new Date(item.updateTime);
          return (
            (!startDate || updateTime >= startDate) &&
            (!endDate || updateTime <= endDate)
          );
        });
      }

      this.new_list_len = arr1.length;
      if (all) {
        return arr1;
      }
      return arr1.length > this.search_num
        ? arr1.slice(0, this.search_num * this.new_list_num)
        : arr1;
    },

    search_arr1(arr) {
      let arr1 = arr.filter((item) => {
        item.commodity_id = item.commodity_id == null ? "" : item.commodity_id;
        item.basic = item.basic == null ? "" : item.basic;
        if (this.search == "未清零" && item.bannum.length > 0) {
          item.on = item.bannum.some((bannum_item) => {
            bannum_item.onnum = bannum_item.onnum ? bannum_item.onnum : "";
            bannum_item.disnum = bannum_item.disnum ? bannum_item.disnum : "";
            return bannum_item.onnum != "" || bannum_item.disnum != "";
          });
          if (item.on) {
            return true;
          } else {
            return (
              item.basic.toLowerCase().indexOf(this.search.toLowerCase()) !=
                -1 ||
              item.text.toLowerCase().indexOf(this.search.toLowerCase()) !=
                -1 ||
              item.commodity_id
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) != -1 ||
              item.beiid.toLowerCase().indexOf(this.search.toLowerCase()) != -1
            );
          }
        } else if (item.bannum.length > 0) {
          item.on = item.bannum.some((bannum_item) => {
            bannum_item.money_jiu = bannum_item.money;
            bannum_item.color_jiu = bannum_item.color;
            if (!bannum_item.money) {
              bannum_item.money_jiu = "0";
            }
            if (!bannum_item.color) {
              bannum_item.color_jiu = "";
            }
            return (
              bannum_item.money_jiu
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) != -1 ||
              bannum_item.color_jiu
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) != -1
            );
          });
          if (item.on) {
            return true;
          } else {
            return (
              item.basic.toLowerCase().indexOf(this.search.toLowerCase()) !=
                -1 ||
              item.text.toLowerCase().indexOf(this.search.toLowerCase()) !=
                -1 ||
              item.commodity_id
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) != -1 ||
              item.beiid.toLowerCase().indexOf(this.search.toLowerCase()) != -1
            );
          }
        } else {
          return (
            item.basic.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
            item.text.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
            item.commodity_id
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) != -1 ||
            item.beiid.toLowerCase().indexOf(this.search.toLowerCase()) != -1
          );
        }
      });
      this.new_list_len = arr1.length;
      if (arr1.length > this.search_num) {
        return arr1.slice(0, this.search_num * this.new_list_num);
      }
      return arr1;
    },

    copySkuId() {
      const uniqueIds = {};
      const newStyleIds = {};
      const commonIds = {};

      this.list.forEach((item) => {
        // 检查 bannum 中是否有任何一个对象的 surnum 大于 0
        const hasSurnumGreaterThanZero = item.bannum.some(
          (ban) => parseInt(ban.surnum) > 0
        );

        // 检查 item.basic 和 item.text 是否包含 "新款式"
        const containsNewStyle =
          item.basic.includes("新款式") ||
          item.text.includes("新款式") ||
          item.text == `()
<i> </i>`;
        const doesNotContainNewStyle = !containsNewStyle;

        if (hasSurnumGreaterThanZero && item.commodity_id && item.updateTime) {
          const updateTime = new Date(item.updateTime);

          const record = {
            id: item.commodity_id,
            updateTime: updateTime
              .toLocaleString("zh-CN", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false,
              })
              .replace(/\//g, "-")
              .replace(",", ""), // 格式化日期
            originalTime: updateTime, // 保留原始时间用于比较
          };

          // 符合条件的记录
          if (doesNotContainNewStyle) {
            if (
              !uniqueIds[item.commodity_id] ||
              updateTime > uniqueIds[item.commodity_id].originalTime
            ) {
              uniqueIds[item.commodity_id] = record;
            }
          } else {
            // 包含 "新款式" 的记录
            if (
              !newStyleIds[item.commodity_id] ||
              updateTime > newStyleIds[item.commodity_id].originalTime
            ) {
              newStyleIds[item.commodity_id] = record;
            }

            // 检查是否在不包含新款式的数组中也存在相同 id
            if (uniqueIds[item.commodity_id]) {
              commonIds[item.commodity_id] = uniqueIds[item.commodity_id];
            }
          }
        }
      });

      // 获取唯一 ID 的数组并按时间排序
      const idsWithUpdateTime = Object.values(uniqueIds).sort(
        (a, b) => b.originalTime - a.originalTime
      );
      const newStyleIdsWithUpdateTime = Object.values(newStyleIds).sort(
        (a, b) => b.originalTime - a.originalTime
      );

      // 生成 commonContent
      const commonContent = [];
      newStyleIdsWithUpdateTime.forEach((newStyleItem) => {
        const matchingItemInUniqueIds = idsWithUpdateTime.find(
          (item) => item.id === newStyleItem.id
        );
        if (matchingItemInUniqueIds) {
          commonContent.push(
            `${matchingItemInUniqueIds.updateTime} ${matchingItemInUniqueIds.id}`
          );
        }
      });

      // 生成最终的复制内容
      const content = idsWithUpdateTime.map(
        (item) => `${item.updateTime} ${item.id}`
      );
      const newStyleContent = newStyleIdsWithUpdateTime.map(
        (item) => `${item.updateTime} ${item.id}`
      );

      const finalContent = JSON.stringify(
        { content, newStyleContent, commonContent },
        null,
        2
      );

      navigator.clipboard
        .writeText(finalContent)
        .then(() => {
          this.$message.success(
            `复制成功，共 ${
              idsWithUpdateTime.length +
              newStyleIdsWithUpdateTime.length +
              commonContent.length
            } 条`
          );
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    },
    copySkuId2() {
      console.log(this.list);
      const uniqueIds = {};

      this.list.forEach((item) => {
        // 检查 bannum 中是否有任何一个对象的 surnum 大于0
        const hasSurnumGreaterThanZero = item.bannum.some(
          (ban) => parseInt(ban.surnum) > 0
        );

        // 检查 item.basic 和 item.text 是否包含 "新款式"
        const doesNotContainNewStyle =
          !item.basic.includes("新款式") && !item.text.includes("新款式");

        if (
          hasSurnumGreaterThanZero &&
          item.commodity_id &&
          item.updateTime &&
          doesNotContainNewStyle
        ) {
          console.log(doesNotContainNewStyle, item.basic, item.text);
          const updateTime = new Date(item.updateTime);

          // 如果commodity_id不存在，或者当前的updateTime更晚，则更新记录
          if (
            !uniqueIds[item.commodity_id] ||
            updateTime > uniqueIds[item.commodity_id].originalTime
          ) {
            uniqueIds[item.commodity_id] = {
              id: item.commodity_id,
              updateTime: updateTime
                .toLocaleString("zh-CN", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: false,
                })
                .replace(/\//g, "-")
                .replace(",", ""), // 格式化日期
              originalTime: updateTime, // 保留原始时间用于比较
            };
          }
        }
      });

      // 获取唯一ID的数组并按时间排序
      const idsWithUpdateTime = Object.values(uniqueIds).sort(
        (a, b) => b.originalTime - a.originalTime
      );

      // 生成最终的复制内容
      const content = idsWithUpdateTime.map(
        (item) => `${item.updateTime} ${item.id}`
      );

      const finalContent = JSON.stringify(content, null, 2);

      navigator.clipboard
        .writeText(finalContent)
        .then(() => {
          this.$message.success(`复制成功，共 ${idsWithUpdateTime.length} 条`);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    },
    // 清空看板链接
    clear_ban_url() {
      let isClearurl = this.list.every((item) => item.link_num == 0);
      if (isClearurl) {
        this.$message({
          message: "无须清空",
          type: "error",
          duration: 1000,
        });
        return;
      }
      this.$axios({
        method: "get", //请求方式
        url: "/api/kanban/clear_ban_url",
      })
        .then((res) => {
          if (res.data == 1) {
            this.$message({
              message: "已清空",
              type: "success",
              duration: 1000,
            });
            this.get_data();
          }
        })
        .catch((err) => {
          this.commonApi.clearToken();
        });
    },
    get_data() {
      this.$socket.emit("data");
    },
    create_data() {
      this.$axios({
        method: "post", // 请求方式修改为 POST
        url: "/api/kanban/create_data",
        data: { numRows: this.numRows }, // 将用户输入的行数作为参数发送到后端
      })
        .then((res) => {
          if (res.data == 1) {
            this.$message({
              message: "已添加",
              type: "success",
              duration: 1000,
            });
            this.get_data();
            this.numRows_dialogVisible = false; // 关闭对话框
          }
        })
        .catch((err) => {
          return;
          this.commonApi.clearToken();
        });
    },
    // 接收鼠标移入元素
    get_show_id(data) {
      this.card_data = data;
    },
    // 鼠标移出
    get_hide_id() {
      this.card_data = {};
    },
    // 生产单搜索
    productionsearch() {
      this.$axios({
        method: "post", //请求方式
        url: "/api/kanban/productionsearch",
        data: {
          value: this.production.search,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.production.list = res.data.data;
          }
        })
        .catch((err) => {
          this.commonApi.cleraToken();
        });
    },
    // 关闭生产单时
    closed_production() {
      this.production.list = [];
      this.production.search = "";
    },
    // 经典语录
    async text_lu() {
      await this.$axios({
        method: "get", //请求方式
        url: "/api/jack/lu/data/one",
      }).then((res) => {
        if (res.data.code == 200) {
          this.text_content = res.data.content;
        }
      });
    },
    // 永久开关语录
    up_text_Vis() {
      localStorage.setItem("text_Vis", this.text_Vis);
    },
    // 设置token
    async setToken(tableName, token) {
      try {
        this.douyincookie = token;
        const res = await this.$axios({
          method: "post",
          url: "/api/kanban/up_doudian_token",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            tableName: tableName,
            token: token,
          },
        });

        if (res.data.code == 200) {
          if (tableName == "douyintoken") {
            await this.sendDouyinIdRequest();
          } else if (tableName == "kdzstoken") {
            await this.sendExpressInventoryRequest();
          }
          this.$message.success("成功");
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
.btn {
  padding: 10px 0;
  width: 150px;
  display: flex;
  justify-content: center;
}
.no_img {
  width: 100%;
  height: 100%;
  background: #fff;
  font-size: 30px;
  text-align: center;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
}
.no_img img {
  max-height: 500px;
  margin-bottom: 20px;
}
.no_img div {
  width: 100%;
}
.list_card,
.card_kanban {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.card_gu {
  border-radius: 4px;
}
.card_gu::-webkit-scrollbar {
  width: 0px;
}
.el-aside {
  color: #333;
}
.head {
  display: flex;
  justify-content: center;
  align-items: center;
}
.head_date {
  /* height: 20px;
display: flex;
overflow: hidden;
cursor: url(/public/img/cursor/hand.png) 0 0 ,auto;
width: 20px;
margin-right: 10px;
border-radius: 50%; */
  margin-right: 4px;
}
/* :deep(.head_date .el-range-editor.el-input__inner){
width: 30px;
margin-right: 10px;
overflow: hidden;
transform: translateY(-10px) translateX(-2px);
} */
.title {
  cursor: url(/public/img/cursor/hand.png) 0 0, auto;
}
.search {
  text-align: center;
  margin-right: 40px;
}
@media (max-width: 1000px) {
  :deep(.search_input input) {
    font-size: 16px;
  }
}
.container {
  height: calc(100%);
  flex-direction: column;
}
.container .text {
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #b3c0d1;
  font-size: 12px;
  color: #222;
  border-bottom: 1px dashed #686b6f;
}
.container .text i {
  cursor: url(/public/img/cursor/hand.png) 0 0, auto;
  margin: 0 5px;
}
.container_con {
  height: 300px;
  flex-grow: 1;
  position: relative;
  background-image: url("../../public/img/bg_img/img_s10004.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.main {
  overflow-y: scroll;
  position: relative;
}
.main::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url("../../public/img/bg_img/img_s10001.jpg"); */
  /* background-image: url("../../public/img/bg_img/img_s10004.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

/* 搜索框 */
.search {
  width: 120px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 60px;
  max-width: 426px;
  min-width: 426px;
  /* border:1px dashed #686b6f; box-sizing: border-box; border-radius:4px; */
}
:deep(.search .el-input input),
:deep(.search div) {
  width: 140px;
  height: 30px;
  line-height: 30px;
}
:deep(.search .el-input__icon) {
  line-height: 30px;
}

.main .coffee {
  position: absolute;
  z-index: 2;
  width: 100px;
}
.aside {
  background: #eef1f6;
  width: 300px;
  position: relative;
}
.card_kanban {
  display: block;
}
.aside::-webkit-scrollbar,
.main::-webkit-scrollbar {
  width: 6px;
}
.aside::-webkit-scrollbar-track,
.main::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}
.aside::-webkit-scrollbar-thumb,
.main::-webkit-scrollbar-thumb {
  background: #c6c6c6;
  border-radius: 4px;
}
.production {
  padding: 0 4px;
}
.production_head {
  margin-bottom: 10px;
}
.production_list .production_item {
  line-height: 30px;
  margin-bottom: 4px;
  padding: 4px 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px dashed #898686;
  display: flex;
}
.production_list .production_clear {
  background: #d5d5d5;
}
.production_text {
  flex: 1;
}
.production_img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
}
.production_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* 备货单日期 */
:deep(.beihuodan_header_riqi input) {
  padding: 0 8px;
  font-size: 12px;
}
.beihuodan_header {
  border-bottom: 2px dashed #afafaf;
  padding-bottom: 8px;
}
.beihuodan_results {
  font-size: 12px;
  height: calc(100vh - 550px);
  padding-bottom: 100px;
}
.item-info {
  width: 60px;
  word-wrap: break-word;
  word-break: break-all;
  padding: 0 4px;
}
.item-totalCount {
  width: 40px;
  text-align: center;
}
.beihuodan_results .beihuo_item {
  display: flex;
  align-items: start;
  border-bottom: 1px dashed #5a5a5a;
}
.beihuo_list .beihuo_item {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.beihuodan_results .beihuo_item .beihuo_img {
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.beihuodan_results .beihuo_item .beihuo_img .item-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.beihuo_re_header {
  height: 30px;
}
.sku-name {
  width: 90px;
}
/* 库存 */
.kucun_results {
  font-size: 12px;
}
.kucun_results .kucun_item {
  display: flex;
  min-height: 53px;
  max-height: 60px;
}
.kucun_results_header {
  text-align: center;
}
.kucun_top .kucun_item {
  min-height: 30px;
}
.kucun_color {
  width: 80px;
}
.kucun_img {
  width: 50px;
}
.kucun_salableItemStock {
  width: 30px;
  text-align: center;
}
.kucun_salableItemDistributableStock {
  width: 30px;
  text-align: center;
}
.kucun_pan {
  flex: 1;
}
.kucun_results {
  padding-bottom: 100px;
  height: calc(100vh - 420px);
}

/* 侧边图片 */
.el-tabs__nav-wrap {
  background: pink;
  padding-right: 40px;
}
.sidebar-icon {
  position: absolute;
  top: 1px;
  right: 8px;
  color: #867f7f;
  z-index: 9;
  border-radius: 50%;
  border: 1px solid #867f7f;
  width: 34px;
  height: 34px;
}
.sidebar-icon i {
  font-size: 34px;
}
/* :deep(#tab-production) {
  margin-right: 40px;
} */

.take_div {
  width: 300px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 0 4px #5a5a5a;
  overflow: hidden;
}

/* 剪切板 */
:deep(.my-dialog .el-dialog__body) {
  max-height: 500px;
  overflow: auto;
}

/** 设置 token */
.token-item {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  margin: 5px 0;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
